import i18n from "../../resources/I18n";
import * as MENU_CONFIG from "../../util/MenuConfig";
import "./stepindicator.css";

export interface IStepIndicator {}

export default function StepIndicator(props: IStepIndicator) {
  return (
    <div className="step-indicator-container">
      <div className="step-indicator">
        <dsd-step-indicator
          skip-to-content-id="main-content-title"
          substeps-numbering="false"
          steps={i18n.language === "fr" ? MENU_CONFIG.FR : MENU_CONFIG.EN}
          data-testid="step-indicator"
        >
          <span slot="title">{i18n.t("jampp:demander-pret-personnel")}</span>
          <span slot="subtitle-mobile">{i18n.t("jampp:voir-details-etapes")}</span>
          <span slot="subtitle">{i18n.t("jampp:etape-5-de-8")}</span>
        </dsd-step-indicator>
      </div>
    </div>
  );
}
