import { CybermetrieParams } from "../models/CybermetrieParams";
import { Question } from "../models/question";
import { ErreurHTTP } from "./Constantes";
import { CybermetrieService } from "@oel/js-cybermetrie";

export class Cybermetries {
  
  static EnvoieFormulaireEtape4(cybermetrieParams: CybermetrieParams) {
    const dimensionsPersonnalisees = new Map<string, string>();
    dimensionsPersonnalisees.set("transactionId", cybermetrieParams.noDemande);
    dimensionsPersonnalisees.set("extraAttribut", cybermetrieParams.typeRenouvellement);
    CybermetrieService.envoyerEvenement({
      event: "interractionsPages",
      category: "Formulaire",
      action: "etape-4",
      label: `frm - renouvellement hypothecaire - assurance pret|${cybermetrieParams.typeProtection}`,
    },
    dimensionsPersonnalisees
    );
  }

  static EnvoieErreurTechnique(erreurStatut: ErreurHTTP, cybermetrieParams: CybermetrieParams) {
    const dimensionsPersonnalisees = new Map<string, string>();
    dimensionsPersonnalisees.set("extraAttribut", cybermetrieParams.typeRenouvellement);
    dimensionsPersonnalisees.set("messageErreur", erreurStatut >= ErreurHTTP.ErreurServeur
    ? erreurStatut?.toString() + "|Service indisponible"
    : erreurStatut?.toString() + "|Erreur technique");
    CybermetrieService.envoyerEvenement({
      event: "interractionsPages",
      category: "Formulaire",
      action: "erreur technique",
      label: `frm - renouvellement hypothecaire - assurance pret`        
    },
    dimensionsPersonnalisees
    );
  }

  static EnvoieErreurReponse(cybermetrieParams: CybermetrieParams) {
    const dimensionsPersonnalisees = new Map<string, string>();
    dimensionsPersonnalisees.set("transactionId", cybermetrieParams.noDemande);
    dimensionsPersonnalisees.set("extraAttribut", cybermetrieParams.typeRenouvellement);
    dimensionsPersonnalisees.set("messageErreur", "400|BadRequest Champ obligatoire");
    CybermetrieService.envoyerEvenement({
      event: "interractionsPages",
      category: "Formulaire",
      action: "erreur utilisateur",
      label: `frm - renouvellement hypothecaire - assurance pret|${cybermetrieParams.typeProtection}`,
    },
    dimensionsPersonnalisees
    );
  }

  static EnvoieParlerConseiller(cybermetrieParams: CybermetrieParams, estVerdictRA?: boolean) {
    const dimensionsPersonnalisees = new Map<string, string>();
    dimensionsPersonnalisees.set("transactionId", cybermetrieParams.noDemande);
    dimensionsPersonnalisees.set("extraAttribut", cybermetrieParams.typeRenouvellement);
    CybermetrieService.envoyerEvenement(
      {
        event: "interractionsPages",
        category: "Renouvellement hypothecaire",
        action: "Clic",
        label: estVerdictRA ? 'aprhn - eof - communiquer avec nous' : 'aprhn - planifier un appel',
      },
      dimensionsPersonnalisees
    );
  }

  static EnvoieQuestionsEvent(questions: Question[] | null | undefined, cybermetrieParams: CybermetrieParams) {
    const questionsSansReponse = questions?.filter(
      (question) => question.reponse == null
    );
    if (questionsSansReponse?.length > 0) {     
      this.EnvoieErreurReponse(cybermetrieParams);     
    } else {
      this.EnvoieContinuerEvent(cybermetrieParams);
    }
  }

  static EnvoieContinuerEvent(cybermetrieParams: CybermetrieParams) {
    const dimensionsPersonnalisees = new Map<string, string>();
    dimensionsPersonnalisees.set("transactionId", cybermetrieParams.noDemande);
    dimensionsPersonnalisees.set("extraAttribut", cybermetrieParams.typeRenouvellement);
    dimensionsPersonnalisees.set("extraAttribut2", "accepter|" + cybermetrieParams.typeAssurance);
    CybermetrieService.envoyerEvenement({
      event: "interractionsPages",
      category: "Formulaire",
      action: "complete",
      label: `frm - renouvellement hypothecaire - assurance pret|${cybermetrieParams.typeProtection}`,
    },
    dimensionsPersonnalisees
    );
  }

  static EnvoieVerdictRA(cybermetrieParams: CybermetrieParams) {
    const dimensionsPersonnalisees = new Map<string, string>();
    dimensionsPersonnalisees.set("transactionId", cybermetrieParams.noDemande);
    dimensionsPersonnalisees.set("extraAttribut", cybermetrieParams.typeRenouvellement);
    dimensionsPersonnalisees.set("extraAttribut2", "referer|" + cybermetrieParams.typeAssurance);
    CybermetrieService.envoyerEvenement({
      event: "interractionsPages",
      category: "Formulaire",
      action: "eof",
      label: `frm - renouvellement hypothecaire - assurance pret|${cybermetrieParams.typeProtection}`,
    },
    dimensionsPersonnalisees
    );
  }

  static EnvoieContinuerSansAssuranceEvent(cybermetrieParams: CybermetrieParams) {
    const dimensionsPersonnalisees = new Map<string, string>();
    dimensionsPersonnalisees.set("transactionId", cybermetrieParams.noDemande);
    dimensionsPersonnalisees.set("extraAttribut", cybermetrieParams.typeRenouvellement);
    CybermetrieService.envoyerEvenement({
        event: "interractionsPages",
        category: "Renouvellement hypothecaire",
        action: "Clic",
        label: "aprhn - renouveler sans assurance",
      },
      dimensionsPersonnalisees
    );
  }

  static EnvoieEnregistrerEvent(cybermetrieParams: CybermetrieParams) {
    const dimensionsPersonnalisees = new Map<string, string>();
    dimensionsPersonnalisees.set("transactionId", cybermetrieParams.noDemande);
    dimensionsPersonnalisees.set("extraAttribut", cybermetrieParams.typeRenouvellement);
    CybermetrieService.envoyerEvenement({
      event: "interractionsPages",
      category: "Renouvellement hypothecaire",
      action: "Clic",
      label: "aprhn - enregistrer et quitter",
    },
    dimensionsPersonnalisees
    );
  }
}
