import React, { useEffect, useRef } from "react";
import { InformationsPreAssurabilite } from "../../models";
import { Assure as modelAssure } from "../../models/assure";
import { Question as modelQuestion } from "../../models/question";
import i18n from "../../resources/I18n";
import { Decision, EtatDemande, Exigence } from "../../util/Constantes";
import "./assure.css";
import { DetailsCoemprunteur } from "./DetailsCoemprunteur";
import { DsdColumn } from "../DSD/DsdColumn";
import { DsdGrid } from "../DSD/DsdGrid";
import { DsdRow } from "../DSD/DsdRow";
import Question from "./Question";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { useCybermetrieContext } from "../utils/CybermetrieContext";
import { CybermetrieParams } from "../../models/CybermetrieParams";
import { Cybermetries } from "../../util/Cybermetrie";
import { obtenirURLClickToCall } from "../../util/ClickToCall";

interface IAssureProps {
  coEmprunteur: boolean;
  informations: modelAssure;
  questions?: modelQuestion[];
  handleReponseQuestion?: any;
  idAssure?: string;
  informationsPreAssurabilite?: InformationsPreAssurabilite;
}

function Assure(props: Readonly<IAssureProps>) {
  let cybermetrieParams = useCybermetrieContext();

  const ancienneValeurCybermetrieParams = useRef(new CybermetrieParams());

  useEffect(() => {
    ancienneValeurCybermetrieParams.current = cybermetrieParams;
  }, [cybermetrieParams]);

  function determinerAlerteInformation() {
    const informationsPreAssurabilite = props.informationsPreAssurabilite;
    const deuxiemeAssure = props.informationsPreAssurabilite.assures?.find((assure) => assure.id !== props.idAssure);

    if (informationsPreAssurabilite.exigence === Exigence.RapportAssurabilite) {
      Cybermetries.EnvoieVerdictRA(ancienneValeurCybermetrieParams.current);
      return (
        <dsd-alert
          data-testid="alerte"
          type="warning"
          id="message-alerte-information"
          full-width={false}
          dynamic={false}
          tabIndex={0}
        >
          <h4 slot="title">{i18n.t("message-RA-requis:titre-modale")}</h4>
          <div aria-live="polite">
            <div className="ligne-haut-message-ra">
              <span
                className="prefixe-message-ra"
                dangerouslySetInnerHTML={{ __html: i18n.t("message-RA-requis:prefix-message-RA-requis") }}
              ></span>
              <dsd-link custom-sr-only={i18n.t("message-RA-requis:texte-hors-ecran")}>
                <a
                  onClick={() => Cybermetries.EnvoieParlerConseiller(ancienneValeurCybermetrieParams.current, true)}
                  href={obtenirURLClickToCall(
                    ancienneValeurCybermetrieParams.current.noDemande,
                    props.informationsPreAssurabilite.transit,
                    "Renouvellement Hypothecaire Numerique",
                    "FIN_RenouvHyp"
                  )}
                >
                  {i18n.t("message-RA-requis:planifier-appel-text")}
                </a>
              </dsd-link>
              <span dangerouslySetInnerHTML={{ __html: i18n.t("message-RA-requis:avec-nous") }}></span>
            </div>
            <div className="ligne-bas-message-ra">
              <span dangerouslySetInnerHTML={{ __html: i18n.t("message-RA-requis:sufix-message-RA-requis") }}></span>
            </div>
          </div>
        </dsd-alert>
      );
    }

    if (informationsPreAssurabilite.decision === Decision.Accepte) {
      if (informationsPreAssurabilite.assures.length === 1) {
        return (
          <dsd-alert
            type="confirmation"
            id="message-alerte-information"
            data-testid="message-alerte-information"
            full-width={false}
            dynamic={false}
            tabIndex={0}
          >
            <h4 slot="title">Confirmation</h4>
            <div aria-live="polite" data-testid="alerte-information">
              <p>
                <span dangerouslySetInnerHTML={{ __html: i18n.t("assurabilite:message-un-seul-emprunteur") }}></span>
              </p>
            </div>
          </dsd-alert>
        );
      }

      if (informationsPreAssurabilite.assures.length === 2) {
        if (deuxiemeAssure.etatDemande === EtatDemande.NonTermine) {
          return (
            <dsd-alert
              type="confirmation"
              id="message-alerte-information"
              data-testid="message-alerte-information"
              full-width={true}
              dynamic={false}
              tabIndex={0}
            >
              <h4 slot="title">Confirmation</h4>
              <div aria-live="polite" data-testid="alerte-information">
                <p>
                  <span dangerouslySetInnerHTML={{ __html: i18n.t("assurabilite:message-emprunteur-un") }}></span>
                </p>
              </div>
            </dsd-alert>
          );
        }

        if (deuxiemeAssure.etatDemande === EtatDemande.Termine) {
          return (
            <dsd-alert
              type="confirmation"
              id="message-alerte-information"
              data-testid="message-alerte-information"
              full-width={true}
              dynamic={false}
              tabIndex={0}
            >
              <h4 slot="title">Confirmation</h4>
              <div aria-live="polite" data-testid="alerte-information">
                <p>
                  <span dangerouslySetInnerHTML={{ __html: i18n.t("assurabilite:message-emprunteur-deux") }}></span>
                </p>
              </div>
            </dsd-alert>
          );
        }
      }
    }

    return <></>;
  }

  const nodeRef = React.useRef(null);

  const mapperQuestions = props?.questions?.map((question, index) => (
    <CSSTransition nodeRef={nodeRef} key={question.id + question.step} timeout={100} classNames="question-transition">
      <div ref={nodeRef} data-testid="question">
        <Question question={question} handleReponseQuestion={props.handleReponseQuestion} />
      </div>
    </CSSTransition>
  ));

  return (
    <>
      {props.questions?.length > 0 && (
        <dsd-container data-testid="container-assure" id="container-assure">
          <DsdGrid>
            <DsdRow>
              <DsdColumn col="12" mdCol="12">
                <h4 className="dsd-t-4" data-testid="nom-assure" id="nom-assure">
                  {props.informations.prenom} {props.informations.nom}
                </h4>
              </DsdColumn>
              <DsdColumn lgCol="12" xlCol="11">
                {props.coEmprunteur ? (
                  <div data-testid="details-coemprunteur">
                    <DetailsCoemprunteur informationAssure={props.informations} />
                  </div>
                ) : (
                  <fieldset aria-describedby="legend-nom-assure">
                    <legend id="legend-nom-assure" className="dsd-sr-only">
                      {props.informations.prenom}&nbsp;{props.informations.nom}
                    </legend>
                    <TransitionGroup>{mapperQuestions}</TransitionGroup>
                  </fieldset>
                )}
              </DsdColumn>
            </DsdRow>
          </DsdGrid>
        </dsd-container>
      )}
      {!props.coEmprunteur && determinerAlerteInformation()}
    </>
  );
}

export default Assure;
