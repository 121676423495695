import { DsdAlerte } from "../DSD/DsdAlerte";
import './alerteinformation.css'

interface IAlerteInformationProps {
  messageAlerteInformation: string;
  typeAlerte: string;
}

function AlerteInformation(props: Readonly<IAlerteInformationProps>) {
  return (
    <DsdAlerte type={props.typeAlerte} id="message-alerte-information" datatestid="message-alerte-information" fullWidth={true} dynamic={false} tabIndex={0}>
      <p>
        <span dangerouslySetInnerHTML={{ __html: props.messageAlerteInformation }}></span>
      </p>
    </DsdAlerte>
  );
}

export default AlerteInformation;
