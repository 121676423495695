import i18n from "../../resources/I18n";
import { DsdColumn } from "../DSD/DsdColumn";
import { DsdButton } from "../DSD/DsdButton";
import "./boutons.css";
import { DsdRow } from "../DSD/DsdRow";
import { INavigationParcours, majCookieParcourAssurance, obtenirNavigationUrl } from "../../util/Navigation";
import { Navigation } from "../../util/Constantes";
import ModaleEnregistrer from "./ModaleEnregistrer";
import ModaleContinuerSA from "./ModaleContinuerSA";

interface IBoutonsProps {
  onClickContinuer?: () => void;
  onClickEtatModaleEnregistrer: (etat: boolean, declencheur: string) => void;
  onClickEtatModaleContinuerSA: (etat: boolean) => void;
  modaleEnregistrerOuvert: boolean;
  modaleContinuerSAOuvert: boolean;
  cookieParcoursAssurance: INavigationParcours;
  showBtnContinuer?: boolean;
}

function Boutons(props: Readonly<IBoutonsProps>) {
  const onClickBoutonPrecedent = () => {
    majCookieParcourAssurance(Navigation.Precedent);
    window.location.assign(obtenirNavigationUrl(props.cookieParcoursAssurance.idCallback));
  };

  const afficherModaleEnregistrer = () => {
    props.onClickEtatModaleEnregistrer(true, "btn-enregistrer");
  };

  const afficherModaleContinuerSA = () => {
    props.onClickEtatModaleContinuerSA(true);
  };

  return (
    <div role="application">
      <ModaleEnregistrer
        modaleOuvert={props.modaleEnregistrerOuvert}
        onClickModaleEnregistrerFermer={props.onClickEtatModaleEnregistrer}
      />
      <ModaleContinuerSA
        modaleOuvert={props.modaleContinuerSAOuvert}
        onClickModaleContinuerSAFermer={props.onClickEtatModaleContinuerSA}
        cookieParcoursAssurance={props.cookieParcoursAssurance}
      />
      <DsdRow datatestid="boutons-container" id="boutons-container">
        <DsdColumn>
          {props.showBtnContinuer !== false && (
            <>
              <DsdButton
                datatestid="btn-continuer"
                variant="primary"
                id="btn-continuer"
                onClick={props.onClickContinuer}
              >
                {i18n.t("assurabilite:btn-continuer")}
              </DsdButton>
              <DsdButton
                id="btn-continuer-sans-assurance"
                datatestid="btn-continuer-sans-assurance"
                onClick={afficherModaleContinuerSA}
              >
                {i18n.t("assurabilite:btn-continuer-sans-assurance")}
              </DsdButton>
            </>
          )}
          {props.showBtnContinuer === false && (
            <DsdButton
              variant="primary"
              id="btn-continuer-sans-assurance"
              datatestid="btn-continuer-sans-assurance"
              onClick={afficherModaleContinuerSA}
            >
              {i18n.t("assurabilite:btn-continuer-sans-assurance")}
            </DsdButton>
          )}
          <DsdButton datatestid="btn-precedent" id="btn-precedent" onClick={onClickBoutonPrecedent}>
            {i18n.t("assurabilite:btn-precedent")}
          </DsdButton>
          <DsdButton id="btn-enregistrer" datatestid="btn-enregistrer" onClick={afficherModaleEnregistrer}>
            {i18n.t("assurabilite:btn-enregistrer")}
          </DsdButton>
        </DsdColumn>
      </DsdRow>
    </div>
  );
}

export default Boutons;
