import i18n from "../../resources/I18n";
import { DsdColumn } from "../DSD/DsdColumn";
import { DsdGrid } from "../DSD/DsdGrid";
import { DsdRow } from "../DSD/DsdRow";
import * as TYPE_PARCOURS from "../../constants/typeParcours";
import InstructionQuestionnaire from "./InstructionQuestionnaire";
import { Question as modelQuestion } from "../../models/question";
import React from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Question from "./Question";
import "./assure.css";
import { Exigence, Decision } from "../../util/Constantes";
import AlerteInformation from "./AlerteInformation";
import { InformationsPreAssurabilite } from "../../models";

interface IAssureProps {
  parcours: string;
  questions: modelQuestion[];
  handleReponseQuestion?: any;
  informationsPreAssurabilite?: InformationsPreAssurabilite;
}

function Assure(props: Readonly<IAssureProps>) {
  const nodeRef = React.useRef(null);

  function determinerAlerteInformation() {
    const informationsPreAssurabilite = props.informationsPreAssurabilite;
    const parcours = props.parcours;

    if (informationsPreAssurabilite.exigence === Exigence.RapportAssurabilite) {
      return (
        <AlerteInformation typeAlerte="info" messageAlerteInformation={i18n.t("jampp:message-RA-requis")} />
      );
    }

    if (informationsPreAssurabilite.decision === Decision.Accepte && parcours === TYPE_PARCOURS.AUTONOME) {
      return (
        <AlerteInformation
          typeAlerte="confirmation"
          messageAlerteInformation={i18n.t("jampp:autonome-message-decision-accepte")}
        />
      );
    }

    if (informationsPreAssurabilite.decision === Decision.Accepte && parcours === TYPE_PARCOURS.ASSISTE) {
      return (
        <AlerteInformation
          typeAlerte="confirmation"
          messageAlerteInformation={i18n.t("jampp:assiste-message-decision-accepte")}
        />
      );
    }

    return <></>;
  }

  const mapperQuestions = props?.questions?.map((question) => (
    <CSSTransition nodeRef={nodeRef} key={question.id} timeout={100} classNames="question-transition">
      <div ref={nodeRef}>
        <Question question={question} handleReponseQuestion={props.handleReponseQuestion} />
      </div>
    </CSSTransition>
  ));

  return (
    <>
      <dsd-container>
        <DsdGrid>
          <DsdRow>
            <DsdColumn col="12" mdCol="12">
              <h3>{i18n.t("jampp:questionnaire-titre")}</h3>
              {props.parcours === TYPE_PARCOURS.ASSISTE && (
                <div className="instruction-questionnaire">
                  <InstructionQuestionnaire />
                </div>
              )}
            </DsdColumn>
            <DsdColumn>
              <TransitionGroup>{mapperQuestions}</TransitionGroup>
            </DsdColumn>
          </DsdRow>
        </DsdGrid>
      </dsd-container>
      {determinerAlerteInformation()}
    </>
  );
}

export default Assure;
