import i18n from "../../resources/I18n";
import "./clickToCall.css";
import { obtenirURLClickToCall } from "../../util/ClickToCall";
import { Cybermetries } from "../../util/Cybermetrie";
import { useCybermetrieContext } from "../utils/CybermetrieContext";
import { useEffect, useRef } from "react";
import { CybermetrieParams } from "../../models/CybermetrieParams";

interface IClickToCall {
  noDemande?: string;
  transit?: string;
  id?: string;
}

export default function ClickToCall(props: Readonly<IClickToCall>) {
  let cybermetrieParams = useCybermetrieContext();

  const ancienneValeurCybermetrieParams = useRef(new CybermetrieParams());

  useEffect(() => {
    ancienneValeurCybermetrieParams.current = cybermetrieParams;
  }, [cybermetrieParams]);

  return (
    <div id={props.id} data-testid="container-click-to-call">
      <h2 id="titre-click-to-call">{i18n.t("jampp:click-to-call-titre")}</h2> <hr data-testid="ligne-noire" />
      <div className="container-ctc">
        <div className="container-demandez-appel">
          <b data-testid="demandez-appel">{i18n.t("jampp:demandez-appel")}</b>
          <div className="texte-demandez-appel">
            <p data-testid="click-to-call-texte">{i18n.t("assurabilite:click-to-call-texte")}</p>
          </div>
          <dsd-link-action
            onClick={() => Cybermetries.EnvoieParlerConseiller(ancienneValeurCybermetrieParams.current)}
            data-testid="dsd-link-action"
            type="external"
          >
            <a
              data-testid="clic-appeler-conseiller"
              href={obtenirURLClickToCall(props.noDemande, props.transit, "Pret personnel -Questionnaire d'assurance", "FIN_JAMPP")}
              target="_blank"
              rel="noreferrer"
            >
              {i18n.t("assurabilite:click-to-call-texte-lien")}
              <span className="dsd-sr-only">&nbsp;{i18n.t("sr-only-ouverture-nouvel-onglet")}</span>
            </a>
          </dsd-link-action>
        </div>
      </div>
    </div>
  );
}
