export const config = JSON.stringify({
    typeGabarit: "transactionnel",
    attendreEventChargementContenuTermine: false,
    afficherSpinner: false,
    estModeAuthentifie: false,
    transactionnel: {
      idContenuPrincipal: "contenu",
      domaineVirtuel: "desjardins",
      estContexteN3: false,
      aideContactV2: {
        identifiantMenu: "aidecontact",
        configurationLiensMenu: {},
        dataSection: {
          id: "aidecontact",
          langue: "fr",
          contexte: "web",
          items: [
            {
              id: "aide-contact-menu",
              type: "LienItem",
              libelles: {
                titre: "Aide et contact",
                description: "Aide et contact",
              },
            },
          ],
          evaluationPartielle: false,
        },
      },
    },
  });