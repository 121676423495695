import i18n from "../../resources/I18n";
import { DsdModale } from "../DSD/DsdModale";
import { INavigationParcours, obtenirNavigationUrl } from "../../util/Navigation";
import FonctionUtilitaire from "../utils/FonctionUtilitaire";

interface IModaleEnregistrer {
  modaleOuvert: boolean;
  onClickModaleEnregistrerFermer: Function;
  dateExpirationOffre?: Date;
  cookieParcoursAssurance: INavigationParcours;
  parcours: string;
}

function retournerDateEcheance(date?: Date) {
  let dateEcheance = new Date(date);
  let dateFormater;

  if (i18n.language === "en") {
    dateFormater = FonctionUtilitaire.dateToJJMoisAAAA(dateEcheance, "en");
  } else {
    dateFormater = FonctionUtilitaire.dateToJJMoisAAAA(dateEcheance, "fr");
  }

  return dateFormater;
}

export default function ModaleEnregistrer(props: Readonly<IModaleEnregistrer>) {
  const onClickBoutonEnregistrer = () => {
    window.location.href = `${obtenirNavigationUrl(props.cookieParcoursAssurance.idCallback)}${props.parcours}/${
      props.cookieParcoursAssurance.langue
    }/saveandquit?idSouscription=${props.cookieParcoursAssurance.idSouscription}&idAssure=${
      props.cookieParcoursAssurance.idAssure
    }`;
  };

  return (
    <DsdModale
      id="dsd-modal-enregistrer"
      dataTestId="dsd-modal-enregistrer"
      show={props.modaleOuvert}
      buttonPrimaryLabel={i18n.t("jampp:bouton-principal-modale-enregistrer-et-quitter")}
      buttonSecondaryLabel={i18n.t("jampp:bouton-secondaire-modale-enregistrer-et-quitter")}
      dsdModalSecondaryClick={props.onClickModaleEnregistrerFermer}
      dsdModalPrimaryClick={onClickBoutonEnregistrer}
      spanSlotTitle={i18n.t("jampp:titre-modale-enregistrer-et-quitter")}
    >
      <p>
        <span dangerouslySetInnerHTML={{ __html: i18n.t("jampp:prefix-texte-modale-enregistrer-et-quitter") }}></span>
        <b>{retournerDateEcheance(props.dateExpirationOffre)}</b>
        <span dangerouslySetInnerHTML={{ __html: i18n.t("jampp:sufix-texte-modale-enregistrer-et-quitter") }}></span>
      </p>
    </DsdModale>
  );
}
